import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import taskService from "../../services/task.service";

export const getAllTasks = createAsyncThunk("task/getAllTasks", async ( data,{
    rejectWithValue
}) => {
    try {
        let tasks = await taskService.getAllTasks()
        let notStatusedTasks = tasks.data;
        const map = new Map(Array.from(tasks.data, obj => [obj["taskStatusResource"].id, []]));
        tasks.data.forEach(obj => map.get(obj["taskStatusResource"].id).push(obj));
        tasks.data = {status:Object.fromEntries(map),pureTasks:notStatusedTasks}
        return tasks;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getAllTasksFiltered = createAsyncThunk("task/getAllTasksFiltered", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getAllTasksFiltered(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getTask = createAsyncThunk("task/getTask", async ( data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getTask(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateTask = createAsyncThunk("task/updateTask", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.updateTask(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getSubTask = createAsyncThunk("task/getSubTask", async ( data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getTask(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createTask = createAsyncThunk("task/createTask", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.createTask(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateTaskType = createAsyncThunk("task/updateTaskType", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.updateTaskType(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateCriticalLevel = createAsyncThunk("task/updateCriticalLevel", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.updateCriticalLevel(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateTaskStatus = createAsyncThunk("task/updateTaskStatus", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.updateTaskStatus(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteTask = createAsyncThunk("task/deleteTask", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.deleteTask(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getTaskComments = createAsyncThunk("task/getTaskComments", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getTaskComments(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createTaskComment = createAsyncThunk("task/createTaskComment", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.createTaskComment(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});


export const deleteTaskComment = createAsyncThunk("task/deleteTaskComment", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.deleteTaskComment(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateTaskComment = createAsyncThunk("task/updateTaskComment", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.updateTaskComment(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createSubTask = createAsyncThunk("task/createSubTask", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.createSubTask(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getUsers = createAsyncThunk("task/getUsers", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getUsers(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateTaskUsers = createAsyncThunk("task/updateTaskUsers", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.updateTaskUsers(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getTaskStatus= createAsyncThunk("task/getTaskStatus", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getTaskStatus();
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createTaskStatus = createAsyncThunk("task/createTaskStatus", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.createTaskStatus(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteTaskStatus = createAsyncThunk("task/deleteTaskStatus", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.deleteTaskStatus(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getPendingTask = createAsyncThunk("task/getPendingTask", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getPendingTask(data);
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const approveTask = createAsyncThunk("task/approveTask", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.approveTask(data.params, data.data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const rejectTask = createAsyncThunk("task/rejectTask", async (data, {
    rejectWithValue
}) => {
    try {
        return await taskService.rejectTask(data.params, data.data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getOpenIssues = createAsyncThunk("task/getOpenIssues", async(data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getOpenIssues(data?.data, data?.params);
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const getProgressIssues = createAsyncThunk("task/getProgressIssues", async(data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getProgressIssues(data?.data, data?.params);
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const getResolvedIssues = createAsyncThunk("task/getResolvedIssues", async(data, {
    rejectWithValue
}) => {
    try {
        return await taskService.getResolvedIssues(data?.data, data?.params);
    } catch (error) {
        return rejectWithValue(error);
    }
});

const initialState = {
    taskStatus: [
        {
            id:'OPEN',
            name:'OPEN'
        },
        {
            id:'IN_PROGRESS',
            name:'IN_PROGRESS'
        },
        {
            id:'RESOLVED',
            name:'RESOLVED'
        }
    ],
    openIssueList: [],
    progressIssueList: [],
    resolvedIssueList: [],
    taskList : [],
    allTasks : [],
    taskDetail: {},
    subTaskDetail: {},
    tableLoading: true,
    taskCreateLoading : false,
    filterRequest: {},
    taskColumns: ['DONE', 'TODO', 'IN_PROGRESS', 'CANCELED'],
    taskComments: [],
    users: [],
    tasksPageable:{},
    pendingTaskList:[],
    pendingRefresh:false,
    defaultFilterRequest: {
        data: {
            "title": "",
            "code" : "",
            "deadLine" : null,
            "taskStatusId" : null,
            "userGroupId" : null,
            "userIds" : [],
            "taskVarietyType": "TASK"
        },

        params: {
            page: 0,
            size: 10
        }
    },
    openIssuesSize: 10,
    progressIssuesSize: 10,
    resolvedIssuesSize: 10,
};

export const taskSlice = createSlice({
    name: 'taskSlice',
    initialState,
    reducers: {
        setFilterRequest: (state, action) => {
            state.filterRequest = action.payload;
        },
        setOpenIssuesSize: (state,action) => {
            state.openIssuesSize += action.payload;
        },
        setProgressIssuesSize: (state,action) => {
            state.progressIssuesSize += action.payload;
        },
        setResolvedIssuesSize: (state,action) => {
            state.resolvedIssuesSize += action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createTask.fulfilled, (state, action) => {
            state.taskCreateLoading = false;
        });

        builder.addCase(createTask.pending, (state, action) => {
            state.taskCreateLoading = true;
        });

        builder.addCase(createTask.rejected, (state, action) => {
            state.taskCreateLoading = false;
        });
        builder.addCase(updateTask.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(updateTask.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(updateTask.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(updateTaskType.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(updateTaskType.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(updateTaskType.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(updateCriticalLevel.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(updateCriticalLevel.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(updateCriticalLevel.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(updateTaskStatus.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(updateTaskStatus.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(updateTaskStatus.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(deleteTask.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(deleteTask.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(deleteTask.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(createTaskComment.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(createTaskComment.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(createTaskComment.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(getTaskComments.fulfilled, (state, action) => {
            state.taskComments = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getTaskComments.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getTaskComments.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(getAllTasks.fulfilled, (state, action) => {
            state.taskList = action.payload.data.status;
            state.allTasks = action.payload.data.pureTasks;
            state.tableLoading = false;
        });

        builder.addCase(getAllTasks.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getAllTasks.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(getAllTasksFiltered.fulfilled, (state, action) => {
            state.allTasks = action.payload.data.content;
            state.tasksPageable = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getAllTasksFiltered.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getAllTasksFiltered.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(getTask.fulfilled, (state, action) => {
            state.taskDetail = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getTask.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getTask.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(getSubTask.fulfilled, (state, action) => {
            state.subTaskDetail = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getSubTask.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getSubTask.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(deleteTaskComment.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(deleteTaskComment.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(deleteTaskComment.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(updateTaskComment.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(updateTaskComment.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(updateTaskComment.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(createSubTask.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(createSubTask.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(createSubTask.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.users = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getUsers.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getUsers.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(updateTaskUsers.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(updateTaskUsers.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(updateTaskUsers.rejected, (state, action) => {
            state.tableLoading = false;
        });
        // builder.addCase(getTaskStatus.fulfilled, (state, action) => {
        //     state.taskStatus = action.payload.data;
        //     state.tableLoading = false;

        // });

        builder.addCase(getTaskStatus.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getTaskStatus.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(createTaskStatus.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(createTaskStatus.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(createTaskStatus.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(deleteTaskStatus.fulfilled, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(deleteTaskStatus.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(deleteTaskStatus.rejected, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(getPendingTask.pending, (state, action) => {
            state.tableLoading = true;
            state.pendingTaskList = [];
        });

        builder.addCase(getPendingTask.rejected, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(getPendingTask.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.pendingTaskList = action.payload.data.content;
        });

        builder.addCase(approveTask.pending, (state, action) => {
            state.tableLoading = true;
            state.pendingRefresh = false;
        });

        builder.addCase(approveTask.rejected, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(approveTask.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.pendingRefresh = true;
        });

        builder.addCase(rejectTask.pending, (state, action) => {
            state.tableLoading = true;
            state.pendingRefresh = false;
        });

        builder.addCase(rejectTask.rejected, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(rejectTask.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.pendingRefresh = true;
        });









        builder.addCase(getOpenIssues.rejected, (state, action) => {
            console.log('rejcted');
        });
        builder.addCase(getOpenIssues.pending, (state, action) => {
            console.log('pending');
            state.openIssueList = [];
        });
        builder.addCase(getOpenIssues.fulfilled, (state, action) => {
            state.openIssueList = action?.payload?.data;
        });
        builder.addCase(getProgressIssues.rejected, (state, action) => {
            console.log('rejcted');
        });
        builder.addCase(getProgressIssues.pending, (state, action) => {
            console.log('pending');
            state.progressIssueList = [];
        });
        builder.addCase(getProgressIssues.fulfilled, (state, action) => {
            state.progressIssueList = action?.payload?.data;
        });
        builder.addCase(getResolvedIssues.rejected, (state, action) => {
            console.log('rejcted');
        });
        builder.addCase(getResolvedIssues.pending, (state, action) => {
            console.log('pending');
            state.resolvedIssueList = [];
        });
        builder.addCase(getResolvedIssues.fulfilled, (state, action) => {
            state.resolvedIssueList = action?.payload?.data;
        });
    }
});

export const {
    setSslData,
    setFilterRequest,
    setOpenIssuesSize,
    setProgressIssuesSize,
    setResolvedIssuesSize,
} = taskSlice.actions;

export default taskSlice.reducer;

