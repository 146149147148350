import API from "../configs/axiosInstance";

const getSsl = (params = {},data = {}) => {
    return API({
        url: `/account-service/v1/domains/certificates`,
        method: 'POST',
        data,
        params
    });
};

const getSslDetails = (id) => {
    return API({
        url: `/account-service/v1/domains/certificates/${id}/details`,
        method: 'GET',
    });
};

const sendTickets = (data) => {
    return API({
        url: `/account-service/v1/tickets`,
        method: 'POST',
        data
    });
}

const getLoginHistory = () => {
    return API({
        url:'/account-service/v1/users/login-history',
        method:'GET',
    })
}

const settingsService = {
    getSsl,
    getSslDetails,
    sendTickets,
    getLoginHistory
};

export default settingsService;
