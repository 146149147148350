import API from "../configs/axiosInstance";

const getAuthorithy = (domain) => {
    return API({
        url: `account-service/v1/instant-lookup/start-of-authority/${domain}`,
        method: 'GET',

    });
};

const getInstantDns = (domain) => {
    return API({
        url: `account-service/v1/instant-lookup/dns/${domain}`,
        method: 'GET',

    });
};

const getInstantWhois = (domain) => {
    return API({
        url: `account-service/v1/instant-lookup/whois/${domain}`,
        method: 'GET',

    });
};

const getInstantIpReputation = (ip) => {
    return API({
        url: `account-service/v1/instant-lookup/ip-reputation/${ip}`,
        method: 'GET',

    });
};

const getInstantTechnology = (domain) => {
    return API({
        url: `account-service/v1/instant-lookup/wappalyzer/${domain}`,
        method: 'GET',

    });
};

const getHttpHeader = (domain) => {
    return API({
        url: `data-harvest-service/v1/http-header?domain=${domain}`,
        method: 'POST',
    });
}

const getScreenshot = (domain) => {
    return API({
        url: `data-harvest-service/v1/screenshot?domain=${domain}`,
        method: 'POST',
    });
}

const getSSLCertificate = (domain) => {
    return API({
        url: `data-harvest-service/v1/ssl-certificate?domain=${domain}`,
        method: 'POST',
    });
}

const instantService = {
    getAuthorithy,
    getInstantDns,
    getInstantWhois,
    getInstantIpReputation,
    getInstantTechnology,
    getHttpHeader,
    getScreenshot,
    getSSLCertificate
};

export default instantService;