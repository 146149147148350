import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import performanceService from "../../services/performance.service";

export const pingTest = createAsyncThunk("performanceTest/pingTest", async ( data,{
    rejectWithValue
}) => {
    try {
        return await performanceService.pingTest(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const LoadTimeTest = createAsyncThunk("performanceTest/loadTest", async ( data,{
    rejectWithValue
}) => {
    try {
        return await performanceService.LoadTimeTest(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const sslAnalyze = createAsyncThunk("performanceTest/sslAnalyze", async ( data,{
    rejectWithValue 
}) => {
    try {
        return await performanceService.sslAnalyze(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getSslAnalyze = createAsyncThunk("performanceTest/getSslAnalyze", async ( data,{
    rejectWithValue 
}) => {
    try {
        return await performanceService.getSslAnalyze(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getSslAnalysisTestPageable = createAsyncThunk("performanceTest/getSslAnalysisTestPageable", async ( data,{
    rejectWithValue 
}) => {
    try {
        return await performanceService.getSslAnalysisTestPageable(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getPingTestResult = createAsyncThunk("performanceTest/getPingTestResult", async ( domainId,{
    rejectWithValue
}) => {
    try {
        return await performanceService.getPingTestResult(domainId);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getLoadTimeTestResult = createAsyncThunk("performanceTest/getLoadTimeTestResult", async ( data,{
    rejectWithValue
}) => {
    try {
        return await performanceService.getLoadTimeTestResult(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getDomainsWithPerformanceTest = createAsyncThunk("performanceTest/getDomainsWithPerformanceTest", async ( data,{
    rejectWithValue
}) => {
    try {
        return await performanceService.getDomainsWithPerformanceTest(data?.data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

const initialState = {
    performancePingTest : [],
    performanceLoadTimeTest : [],
    performancegetPingTestResult: [],
    getSslAnalysis : [],
    sslAnalysisResult : null,
    performancegetLoadTimeTestResult: [],
    tableLoading: false,
    testSelectedDomain: null,
    domainsWithPerformanceTests: [],
    domainLoading: false,
    sslTestLoading: false,
    pingTestLoading: false,
    loadTimeTestLoading: false,
};

export const performanceSlice = createSlice({
    name: 'performanceSlice',
    initialState,
    reducers: {
        clearPerformanceData:(state,action) => {
            state.performancegetPingTestResult = [];
            state.performancegetLoadTimeTestResult = [];
            state.getSslAnalysis = [];
        },
        setSslAnalysisResult: (state,action) =>{
            state.sslAnalysisResult = action.payload
        },
        setSelectedDomain:(state,action) => {
            state.testSelectedDomain = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(pingTest.fulfilled, (state, action) => {
            state.performancePingTest = action.payload.data;
            state.pingTestLoading = false;
        });

        builder.addCase(pingTest.pending, (state, action) => {
            state.pingTestLoading = true;
        });

        builder.addCase(pingTest.rejected, (state, action) => {
            state.pingTestLoading = false;
        });

        builder.addCase(LoadTimeTest.fulfilled, (state, action) => {
            state.performanceLoadTimeTest = action.payload.data;
            state.loadTimeTestLoading = false;
        });

        builder.addCase(LoadTimeTest.pending, (state, action) => {
            state.loadTimeTestLoading = true;
        });

        builder.addCase(LoadTimeTest.rejected, (state, action) => {
            state.loadTimeTestLoading = false;
        });
        builder.addCase(sslAnalyze.fulfilled, (state, action) => {
            state.sslTestLoading = false;
        });

        builder.addCase(sslAnalyze.pending, (state, action) => {
            state.sslTestLoading = true;
        });

        builder.addCase(sslAnalyze.rejected, (state, action) => {
            state.sslTestLoading = false;
            state.testSelectedDomain = null;
        });
        builder.addCase(getSslAnalyze.fulfilled, (state, action) => {
            state.getSslAnalysis = action?.payload?.data?.content?.filter(item => item?.status === "READY");
            state.tableLoading = false;
        });

        builder.addCase(getSslAnalyze.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getSslAnalyze.rejected, (state, action) => {
            state.tableLoading = false;

        });
        builder.addCase(getPingTestResult.fulfilled, (state, action) => {
            state.performancegetPingTestResult = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getPingTestResult.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getPingTestResult.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(getLoadTimeTestResult.fulfilled, (state, action) => {
            state.performancegetLoadTimeTestResult = action.payload.data;
            state.tableLoading = false;
        });

        builder.addCase(getLoadTimeTestResult.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getLoadTimeTestResult.rejected, (state, action) => {
            state.tableLoading = false;
        });
        builder.addCase(getDomainsWithPerformanceTest.fulfilled, (state, action) => {
            state.domainsWithPerformanceTests = action.payload.data;
            state.domainLoading = false;
        });
        builder.addCase(getDomainsWithPerformanceTest.pending, (state, action) => {
            state.domainLoading = true;
        });
        builder.addCase(getDomainsWithPerformanceTest.rejected, (state, action) => {
            state.domainLoading = false;
        });
    }
});

export const {
    setPerformanceData,
    clearPerformanceData,
    setSslAnalysisResult,
    setSelectedDomain
} = performanceSlice.actions;

export default performanceSlice.reducer;

