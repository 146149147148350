import API from "../configs/axiosInstance";

const createTask = (data = {}) => {
  return API({
    url: `/account-service/v1/tasks`,
    method: "POST",
    data,
  });
};

const updateTask = (data = {}) => {
  let id = data.id 
  delete data.id
  return API({
    url: `/account-service/v1/tasks/` + id,
    method: "PUT",
    data
  });
};

const updateTaskType = (data = {}) => {
  return API({
    url: `/account-service/v1/tasks/update-task-type/` + data.id,
    method: "PUT",
    params: { taskType: data.taskType },
  });
};

const updateCriticalLevel = (data = {}) => {
  return API({
    url: `/account-service/v1/tasks/update-critical-level/` + data.id,
    method: "PUT",
    params: { criticalLevel: data.criticalLevel },
  });
};

const updateTaskStatus = (data = {}) => {
  return API({
    url: `/account-service/v1/tasks/update-status/` + data.id,
    method: "PUT",
    params: { taskStatus: data.taskStatus },
  });
};

const deleteTask = (data = {}) => {
  return API({
    url: `/account-service/v1/tasks/` + data.id,
    method: "DELETE",
  });
};

const getAllTasks = () => {
  return API({
    url: `/account-service/v1/tasks/all`,
    method: "GET",
  });
};

const getAllTasksFiltered = ({params = {}, data = {}}) => {
  return API({
    url: `/account-service/v1/tasks/pageable`,
    method: "POST",
    data,
    params
  });
};

const getTask = (data = {}) => {
  return API({
    url: `/account-service/v1/tasks/${data.id}`,
    method: "GET",
  });
};

const getTaskComments = (data = {}) => {
  return API({
    url: `/account-service/v1/tasks/${data.id}/task-comment`,
    method: "GET",
  });
};

const createTaskComment = (data = {}) => {
  return API({
    url: `/account-service/v1/task-comments`,
    method: "POST",
    data,
  });
};

const deleteTaskComment = (data = {}) => {
  return API({
    url: `/account-service/v1/task-comments/${data.id}`,
    method: "DELETE",
  });
};

const updateTaskComment = (data = {}) => {
  return API({
    url: `/account-service/v1/task-comments/${data.id}`,
    method: "PUT",
    data: {
      description: data.description,
      taskId: data.taskId
    }
  });
};

const createSubTask = (data = {}) => {
  let id = data.id
  delete data.id
  return API({
    url: `/account-service/v1/tasks/add-subtask/${id}`,
    method: "POST",
    data
  });
};

const getUsers = () => {
  return API({
    url: `/account-service/v1/users/all-users`,
    method: "GET",
  });
};

const updateTaskUsers = (data = {}) => {
  return API({
    url: `/account-service/v1/tasks/update-user/${data.id}`,
    method: "PUT",
    data: data.users
  });
};

const getTaskStatus = (data = {}) => {
  return API({
    url: `/account-service/v1/task-status/all`,
    method: "GET",
  });
};

const createTaskStatus = (data = {}) => {
  return API({
    url: `/account-service/v1/task-status`,
    method: "POST",
    data
  });
};

const deleteTaskStatus = (data = {}) => {
  return API({
    url: `/account-service/v1/task-status/` + data.id,
    method: "DELETE",
  });
};

const getPendingTask = (data = {}) => {
  return API({
    url: `/account-service/v1/pending-tasks/pageable`,
    method: "POST",
    data
  });
};

const approveTask = (params = {}, data = {}) => {
  return API ({
    url: `/account-service/v1/pending-tasks/approve`,
    method: "PUT",
    params
  })
}

const rejectTask = (params = {}, data = {}) => {
  return API ({
    url: `/account-service/v1/pending-tasks/reject`,
    method: "PUT",
    data,
    params
  })
}





const getOpenIssues = (data = {}, params = {}) => {
  return API (
    {
    url: `/account-service/v1/tasks/pageable`,
    method: "POST",
    data,
    params
  })
}

const getProgressIssues = (data = {}, params = {}) => {
  return API (
    {
    url: `/account-service/v1/tasks/pageable`,
    method: "POST",
    data,
    params
  })
}

const getResolvedIssues = (data = {}, params = {}) => {
  return API (
    {
    url: `/account-service/v1/tasks/pageable`,
    method: "POST",
    data,
    params
  })
}



const taskService = {
  createTask,
  getAllTasks,
  getAllTasksFiltered,
  getTask,
  updateTaskType,
  updateCriticalLevel,
  updateTaskStatus,
  deleteTask,
  createTaskComment,
  getTaskComments,
  deleteTaskComment,
  updateTaskComment,
  createSubTask,
  updateTask,
  getUsers,
  updateTaskUsers,
  getTaskStatus,
  createTaskStatus,
  deleteTaskStatus,
  getPendingTask,
  approveTask,
  rejectTask,
  getOpenIssues,
  getProgressIssues,
  getResolvedIssues
};

export default taskService;
