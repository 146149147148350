import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import settingsService from 'services/settingsService';

const initialState = {
    currentTab: 0,
    settingsBurger: false,
    ticketError: null,
    ticketSuccess: null,
    loginHistory:null,
};

export const getAllTags = createAsyncThunk("tags/getAllTags", async ( data,{
    rejectWithValue
}) => {
    try {
        return await settingsService.getAllTags(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const sendTickets = createAsyncThunk("tickets/sendTickets", async ( data,{
    rejectWithValue
}) => {
    try {
        return await settingsService.sendTickets(data);
    }
    catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Unknown error occurred';
        return rejectWithValue(errorMessage); 
    }
});

export const getLoginHistory = createAsyncThunk("settings/getLoginHistory", async (data, {
    rejectWithValue
}) => {
    try {
        return await settingsService.getLoginHistory();
    }
    catch (error) {
        return rejectWithValue(error);
    }
})

export const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState,
    reducers: {
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setSettingsBurger: (state, action) => {
            state.settingsBurger = action.payload;
        },
        clearTicketError: (state) => {
            state.ticketError = null
        },
        clearTicketSuccess: (state) => {
            state.ticketSuccess = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllTags.fulfilled, (state, action) => {
            state.allTags = action.payload.data;
            state.loading = false;
        });

        builder.addCase(sendTickets.fulfilled, (state, action) => {
            if(action?.payload?.response?.data?.error){
                state.ticketError = action.payload.response.status;
            } else { 
                state.ticketSuccess = action?.payload?.status;
            }
        });
        builder.addCase(sendTickets.rejected, (state, action) => {
            state.ticketError = action?.payload?.response?.data?.error;
        });
        builder.addCase(getLoginHistory.fulfilled, (state, action) => {
            state.loginHistory = action.payload.data;
        });
    }
});

export const {
    setCurrentTab,
    setSettingsBurger,
    clearTicketError,
    clearTicketSuccess,
} = settingsSlice.actions;

export default settingsSlice.reducer;